export const cdnService = {
  getCountryCodeList,
  getCurrencies,
  getRestrictedCountries,
  getTestimonials,
};

const cdnUrl = "https://cdn.casinobayhub.com";

async function getCountryCodeList() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const countries = await fetch(
    `${cdnUrl}/resources/countrycodes.json`,
    requestOptions
  ).then(handleResponse);
  return countries;
}

async function getRestrictedCountries() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const countries = await fetch(
    `${cdnUrl}/resources/restricted-countries.json`,
    requestOptions
  ).then(handleResponse);
  return countries;
}

async function getTestimonials() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const testimonials = await fetch(
    `${cdnUrl}/placebo-partners/testimonials.json`,
    requestOptions
  ).then(handleResponse);
  return testimonials;
}

async function getCurrencies() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const countries = await fetch(
    `${cdnUrl}/resources/currencies.json`,
    requestOptions
  ).then(handleResponse);
  return countries;
}

async function handleResponse(response) {
  const handledResponse = await response.text().then(async (text) => {
    const data = text && (await JSON.parse(text));
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return error;
    }
    return data;
  });
  return handledResponse;
}
