import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';
import combinedReducer from './reducers';
import { useMemo } from 'react';
let store;
const bindMiddleware = (middleware) => {
    if (process.env.NODE_ENV !== 'production') {
        const { composeWithDevTools } = require('redux-devtools-extension');
        return composeWithDevTools(applyMiddleware(...middleware));
    }
    return applyMiddleware(...middleware);
};

const reducer = (state, action) => {
    return combinedReducer(state, action);
};

const initStore = () => {
    if (process.env.NODE_ENV !== 'production') {
        return createStore(reducer, bindMiddleware([thunkMiddleware, logger]));
    }
    return createStore(reducer, bindMiddleware([thunkMiddleware]));
};

export const initializeStore = () => {
    let _store = store ?? initStore();

    // After navigating to a page with an initial Redux state, merge that state
    // with the current state in the store, and create a new store
    if (store) {
        _store = initStore({
            ...store.getState()
        });
        // Reset the current store
        store = undefined;
    }

    // For SSG and SSR always create a new store
    if (typeof window === 'undefined') return _store;
    // Create the store once in the client
    if (!store) store = _store;

    return _store;
};

export function useStore(initialState) {
    const store = useMemo(() => initializeStore(initialState), [initialState]);
    return store;
}
