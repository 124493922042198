import "../styles/globals.css";
import "../styles/swiper.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import { Provider, useDispatch } from "react-redux";
import { useStore } from "../store/store";
import Head from "next/head";
import MainModals from "../src/component/MainModals";
import BarLoading from "../src/component/Loading/BarLoading";
import { processActions } from "../store/actions";
import Router from "next/router";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import useScript from "../hooks/useScript";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
function App({ Component, pageProps }) {
  const store = useStore(pageProps.initialReduxState);
  const queryClient = new QueryClient();
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Application Component={Component} {...pageProps} />
      </QueryClientProvider>
    </Provider>
  );
}

const Application = (props) => {
  useScript("/js/tawk.js", 2500);
  const { Component, pageProps } = props;

  const Layout = Component.layout || (({ children }) => <>{children}</>);

  return (
    <>
      <Layout>
        <MainModals />
        <Component {...pageProps} />
      </Layout>
    </>
  );
};

export default App;
