import { cdnTypes } from '../types';
const initialState = {};
export default function cdnReducer(state = initialState, action) {
    switch (action.type) {
        case cdnTypes.GET_COUNTRY_CODE_LIST_SUCCESS:
            return { ...state, countryCodeList: action.countryCodeList };
        case cdnTypes.GET_CURRENCIES_SUCCESS:
            return { ...state, currencies: action.currencies };
        default:
            return state;
    }
}
