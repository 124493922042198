export const modalTypes = {
    SHOW_MODAL: 'SHOW_MODAL',
    HIDE_MODAL: 'HIDE_MODAL',

    SET_MODAL_CONTENT: 'SET_MODAL_CONTENT',

    RESET_MODAL: 'RESET_MODAL',
    CURRENCY: 'CURRENCY',
    LOGIN: 'LOGIN',
    REGISTER: 'REGISTER',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    RESET_PASSWORD: 'RESET_PASSWORD',
    DEPOSIT: 'DEPOSIT',
    CASHOUT: 'CASHOUT',
    IFRAME: 'IFRAME',
    LOADING: 'LOADING'
};
