import { modalTypes } from '../types';

const initialModal = {};

export default function modalReducer(state = initialModal, action) {
    switch (action.type) {
        case modalTypes.SHOW_MODAL:
            return {
                ...state,
                [action.component]: true
            };
        case modalTypes.SET_MODAL_CONTENT:
            return {
                ...state,
                content: action.content
            };
        case modalTypes.HIDE_MODAL:
            return {
                ...state,
                [action.component]: false
            };
        case modalTypes.RESET_MODAL:
            return {};
        default:
            return state;
    }
}
