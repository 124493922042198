import { alertTypes } from "../types";
const initialState = { type: "", message: "" };
export default function alertReducer(state = initialState, action) {
  switch (action.type) {
    case alertTypes.SUCCESS:
      return { ...state, type: action.alertType, message: action.message };

    case alertTypes.ERROR:
      return { ...state, type: action.alertType, message: action.message };
    case alertTypes.CLEAR:
      return initialState;

    default:
      return state;
  }
}
