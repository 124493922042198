import { processTypes } from '../types';

export const processActions = {
    loading,
    startTransition,
    finishTransition,
    complete
};

function startTransition() {
    return (dispatch) => dispatch({ type: processTypes.PAGE_TRANSITION_START });
}
function finishTransition() {
    return (dispatch) => dispatch({ type: processTypes.PAGE_TRANSITION_STOP });
}

function loading() {
    return (dispatch) => dispatch({ type: processTypes.PROCESS_LOADING });
}

function complete() {
    return (dispatch) => dispatch({ type: processTypes.PROCESS_COMPLETED });
}
