import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalTypes } from "../../../store/types";
import { modalActions } from "../../../store/actions";
import { Modal } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import style from "./user.module.scss";
import { affiliateService } from "../../../services/affiliate.services";
import { EyeFill, EyeSlashFill } from "react-bootstrap-icons";

export default function RegisterModal() {
  const showRegister = useSelector((state) => state.modal[modalTypes.REGISTER]);
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(modalActions.resetModal());
  };
  const [state, setState] = useState({ submitted: false, showPassword: false });
  function handleChange(e) {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  }
  function registerAffiliate(e) {
    e.preventDefault();
    affiliateService
      .register(state)
      .then((res) => setState({ ...state, submitted: true }));
  }

  const togglePassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const pwdStyle = {
    border: "none",
    boxShadow: "none",
  };

  return (
    <Modal
      ref={modalRef}
      show={showRegister}
      onHide={handleClose}
      contentClassName={`${style["user-modal"]} ${showRegister}`}
      backdropClassName={style["modal-backdrop"]}
      className={style.modal}
    >
      <Modal.Body className={style["modal-content"]}>
        <div className={style["screen"]}>
          <div className={style["content"]}>
            {state.submitted ? (
              <div className={style.success}>
                <h1>Thank you we get back to you!</h1>
                <span>you can follow up with: </span>
                <a href="mailto:support@placebopartners.com">
                  support@placebopartners.com
                </a>
              </div>
            ) : (
              <form onSubmit={registerAffiliate} className={style["login"]}>
                <div className={style["field"]}>
                  <i className={`${style["icon"]} `}></i>
                  <input
                    type="text"
                    name="fullName"
                    onChange={handleChange}
                    className={`${style["input"]}`}
                    placeholder="Your name"
                  />
                </div>
                <div className={style["field"]}>
                  <i className={`${style["icon"]} `}></i>
                  <input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    className={`${style["input"]}`}
                    placeholder="Email"
                  />
                </div>
                <InputGroup>
                  <Form.Control
                    placeholder="Password"
                    name="password"
                    className={`${style["input"]}`}
                    type={state.showPassword ? "text" : "password"}
                    onChange={handleChange}
                  />
                  <InputGroup.Text>
                    {state.showPassword ? (
                      <EyeSlashFill onClick={togglePassword} />
                    ) : (
                      <EyeFill onClick={togglePassword} />
                    )}
                  </InputGroup.Text>
                </InputGroup>
                <div className={style["field"]}>
                  <i className={`${style["icon"]} `}></i>
                  <input
                    id="Company"
                    name="company"
                    type="text"
                    onChange={handleChange}
                    placeholder="Company"
                    className={`${style["input"]}`}
                  ></input>
                </div>
                <div className={style["field"]}>
                  <i className={`${style["icon"]} `}></i>
                  <input
                    id="url"
                    name="website"
                    type="text"
                    onChange={handleChange}
                    placeholder="Website url"
                    className={`${style["input"]}`}
                  ></input>
                </div>
                <button className={`${style["submit"]}`}>
                  <span className={`${style["text"]}`}>Signup Now</span>
                  <i className={`${style["icon"]}`}></i>
                </button>
              </form>
            )}
          </div>

          <div className={style["background"]}>
            <span className={`${style.shape} ${style.shape4}`}></span>
            <span className={`${style.shape} ${style.shape3}`}></span>
            <span className={`${style.shape} ${style.shape2}`}></span>
            <span className={`${style.shape} ${style.shape1}`}></span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
