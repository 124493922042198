import React from "react";
import RecLoadingModal from "../Loading/RecLoadingModal";
import { useSelector } from "react-redux";
import { modalTypes } from "../../../store/types";
import RegisterModal from "../RegisterModal/RegisterModal";

export default function GeneralModals() {
  const showRegisterModal = useSelector(
    (state) => state.modal[modalTypes.REGISTER]
  );

  return (
    <>
      {showRegisterModal && <RegisterModal />}
      <RecLoadingModal />
    </>
  );
}
