import { modalTypes } from '../types';

export const modalActions = {
    closeModal,
    showModal,
    resetModal,
    setContent
};

function setContent(content) {
    return {
        type: modalTypes.SET_MODAL_CONTENT,
        content: content
    };
}

function closeModal(component) {
    return {
        type: modalTypes.HIDE_MODAL,
        component: component
    };
}

function showModal(component) {
    return {
        type: modalTypes.SHOW_MODAL,
        component: component
    };
}

function resetModal() {
    return {
        type: modalTypes.RESET_MODAL
    };
}
