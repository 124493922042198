import { processTypes } from '../types';
const initialState = {
    loading: false,
    transitioning: true
};
export default function processReducer(state = initialState, action) {
    switch (action.type) {
        case processTypes.PAGE_TRANSITION_START:
            return { ...state, transitioning: true };
        case processTypes.PAGE_TRANSITION_STOP:
            return { ...state, transitioning: false };
        case processTypes.PROCESS_COMPLETED:
            return { ...state, loading: false };
        case processTypes.PROCESS_LOADING:
            return { ...state, loading: true };
        default:
            return state;
    }
}
