import Modal from "@mui/material/Modal";

import React from "react";
import style from "./rec-loading.module.scss";
import { useSelector } from "react-redux";

export default function RecLoadingModal() {
  const showModal = useSelector((state) => state.process.loading);

  return (
    <>
      <Modal
        backdropClassName={style.backdrop}
        open={showModal}
        onClose={() => {}}
        contentClassName={style.modalContent}
        className={style.modal}
      >
        <div className={`${style.loader} ${style["quantum-spinner"]}`}></div>
      </Modal>
    </>
  );
}
