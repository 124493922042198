import { combineReducers } from "redux";
import modalReducer from "./modal.reducer";
import processReducer from "./process.reducer";
import alertReducer from "./alert.reducer";
import cdnReducer from "./cdn.reducer";

export default combineReducers({
  modal: modalReducer,
  process: processReducer,
  cdn: cdnReducer,
  alert: alertReducer,
});
