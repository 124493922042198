export const affiliateService = {
  register,
};

function register(affiliate) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Request-Method": "POST",
    },
    body: JSON.stringify(affiliate),
  };

  return fetch(
    "/affiliate-service/api/v1/affiliate/application",
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    if (!response.ok) {
      throw response;
      // auto logout if 401 response returned from api
    }
    const data = text && JSON.parse(text);
    return data;
  });
}
