import { useEffect } from 'react';

const useScript = (url, timeout = 0) => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = url;
        script.async = true;
        const timer = setTimeout(() => {
            document.body.appendChild(script);
        }, timeout);

        return () => {
            clearTimeout(timer);
            try {
                document.body.removeChild(script);
                // eslint-disable-next-line no-empty
            } catch (ignore) {}
        };
    }, [url]);
};

export default useScript;
